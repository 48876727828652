<template>
	<div
		class="
			w-full lg:w-1/4 p-12
			flex flex-col justify-between
			bg-cool-gray-300 text-cool-gray-700 
			text-center rounded-xl
		"
	>
		<div class="flex-none">
			<h2 class="text-3xl mb-12">
				{{ title }}
			</h2>
			<p v-if="subtitle" class="-mt-10 mb-10">
				{{ subtitle }}
			</p>
			<p class="text-lg">
				There have been {{ signedWaiversCount }} signed waivers. <br />
				This is out the of {{ totalWaiversCount }} total signable
				waivers.
			</p>
		</div>
		<div class="flex-1 my-20">
			<p
				class="text-5xl"
				:class="{
					'text-red-500': getWaiversCountPercent < 100,
					'text-green-700': getWaiversCountPercent >= 100
				}"
			>
				{{ getWaiversCountPercent }}
				%
			</p>
		</div>
	</div>
</template>

<script>
	import { axiosModified } from "@/services/base";

	export default {
		name: "waiver-signed-count-card",
		props: {
			title: {
				type: String,
				default: "",
				required: true
			},
			subtitle: {
				type: String,
				default: "",
				required: false
			},
			performanceFrom: {
				type: String,
				default: "",
				required: true
			},
			performanceTo: {
				type: String,
				default: "",
				required: true
			}
		},
		data: function() {
			return {
				signedWaiversCount: 0,
				totalWaiversCount: 1
			};
		},
		mounted: function() {
			this.fetchWaiverSignedCount();
			setInterval(
				() => this.fetchWaiverSignedCount(),
				// Mins * Seconds * Milliseconds
				1 * 60 * 1000
			);
		},
		computed: {
			getWaiversCountPercent: function() {
				return ((
					this.signedWaiversCount / this.totalWaiversCount
				) * 100).toFixed(2);
			}
		},
		methods: {
			sleep: function(milliseconds) {
				return new Promise(resolve =>
					window.setTimeout(() => resolve(), milliseconds)
				);
			},
			fetchWaiverSignedCount: function() {
				// Emit Fetch Start
				this.$emit("fetchStarted");

				// Perform Fetch
				axiosModified
					.get("/waivers-management/waivers_signed_count", {
						headers: {},
						params: {
							performance_from: this.performanceFrom,
							performance_to: this.performanceTo
						}
					})

					// Display Data
					.then(response => {
						const responseParsed = JSON.parse(response.data.data);
						this.signedWaiversCount =
							responseParsed.signedWaiversCount;
						this.totalWaiversCount =
							responseParsed.totalWaiversCount;
					})

					// Let's wait a couple seconds
					.then(() => this.sleep(2000))

					// Emit Fetch End
					.then(() => this.$emit("fetchFinished"));
			}
		}
	};
</script>
