<template>
	<!-- Flex Col Wrapper -->
	<div class="w-full h-screen relative flex flex-col">
		<!-- - Loading Overlay - -->
		<WaveLoader v-if="isFetching" />

		<!-- - Heading - -->
		<header class="w-full flex-none">
			<div class="w-full bg-gray-700">
				<div class="container mx-auto py-2 lg:p-8 lg:py-3">
					<div class="w-full flex justify-between items-center p-2">
						<div class="text-white p-5">
							<WaveLogo
								class="cursor-pointer h-16 md:h-16 lg:h-20"
							/>
						</div>
						<div
							class="flex content-center flex-wrap h-full text-xs lg:text-sm text-white text-center p-2 font-normal"
						>
							<div class="flex items-center h-16">
								<p
									class="block flex-1 text-center font-semibold"
								>
									Surf Waivers
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>

		<!-- - Content - -->
		<main class="w-full h-full py-4 lg:py-12 overflow-y-auto">
			<!-- - Cards Row - -->
			<section
				class="
					w-full h-full max-w-screen-xl mx-auto
					flex justify-center 
					space-y-8 lg:space-y-0 lg:space-x-12
				"
			>
				<WaiverSignedCountCard
					v-for="(card, index) in cards"
					:key="`card-${index}`"
					:title="card.title"
					:subtitle="card.subtitle"
					:performanceFrom="card.performanceFrom"
					:performanceTo="card.performanceTo"
					@fetchStarted="$set(card, 'fetched', false)"
					@fetchFinished="$set(card, 'fetched', true)"
				/>
			</section>
		</main>
	</div>
</template>

<script>
	// @ is an alias to /src
	import WaveLogo from "@/components/WaveLogo.vue";
	import WaveLoader from "@/components/WaveLoader.vue";
	import WaiverSignedCountCard from "@/components/WaiverSignedCountCard.vue";

	export default {
		components: {
			WaveLogo,
			WaveLoader,
			WaiverSignedCountCard
		},
		data: function() {
			return {
				cards: [
					{
						fetched: false,
						title: "Last 30 days",
						subtitle: "(Not including today)",
						performanceFrom: this.$moment()
							.subtract(30, "d")
							.set("hour", 0)
							.set("minute", 0)
							.set("second", 0)
							.format(),
						performanceTo: this.$moment()
							.subtract(1, "d")
							.set("hour", 23)
							.set("minute", 59)
							.set("second", 59)
							.format()
					},
					{
						fetched: false,
						title: "Last 7 days",
						subtitle: "(Not including today)",
						performanceFrom: this.$moment()
							.subtract(8, "d")
							.set("hour", 0)
							.set("minute", 0)
							.set("second", 0)
							.format(),
						performanceTo: this.$moment()
							.subtract(1, "d")
							.set("hour", 23)
							.set("minute", 59)
							.set("second", 59)
							.format()
					},
					{
						fetched: false,
						title: "Today",
						subtitle: "(Up to now)",
						performanceFrom: this.$moment()
							.set("hour", 0)
							.set("minute", 0)
							.set("second", 0)
							.format(),
						performanceTo: this.$moment().format(
							"YYYY-MM-DDTHH:mm:ss"
						)
					},
					{
						fetched: false,
						title: "Today",
						performanceFrom: this.$moment()
							.set("hour", 0)
							.set("minute", 0)
							.set("second", 0)
							.format(),
						performanceTo: this.$moment()
							.set("hour", 23)
							.set("minute", 59)
							.set("second", 59)
							.format()
					}
				]
			};
		},
		computed: {
			isFetching: function() {
				if (this.cards.find(card => !card.fetched)) return true;
				return false;
			}
		}
	};
</script>
